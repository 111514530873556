import * as React from "react";
import Seo from "../components/seo";
import PageHeader from "../components/page-header";
import Layout from "../layouts/index";
import { Link } from "gatsby";

const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
};

const paragraphStyles = {
  marginBottom: 48,
};
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
};

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title="Page not found" />
      <PageHeader title="Page not found" />
      <section
        className="block grid-container narrow"
        aria-labelledby="pageNotFound"
      >
        <p style={paragraphStyles}>
          It looks like the page you're looking for is no longer here. <br />
          We may have moved the page, or you might have followed a bad link.
        </p>
        <Link to="/" className="button primary hollow">
          return to the homepage
        </Link>
      </section>
    </Layout>
  );
};

export default NotFoundPage;

export const Head = () => <title>Not found</title>;
